<template>
	<div>
		<div class="row mt-4 mb-4">
			<div class="col-sm-12 text-right">
				<h1>Clientes</h1>
			</div>
		</div>

		<DataGrid :config="dataGridConfig" :data="clientes" :select="seleccionar" @actions="dataGridActions">
			<div class="row">
				<div class="col-sm-2">
					<button class="btn secondary-btn" @click="solicitar_acceso">Ver detalles</button>
				</div>
				<div class="col-sm-2">
					<button class="btn warning-btn" @click="obtener_clientes">Recargar</button>
				</div>
			</div>
		</DataGrid>

		<Modal v-if="ver_detalles_cliente" :options="{width: '85vw'}" @close="cerrar_detalles">
			<div class="title">Detalles</div>
			<div class="body">
				<div class="row">
					<div class="col-sm-2 offset-sm-8">
						<button class="btn secondary-btn" @click="ver_creditos = true">Ver créditos</button>
					</div>
					<div class="col-sm-2">
						<button class="btn secondary-btn" @click="ver_observaciones = true">Ver Observaciones</button>
					</div>
				</div>
				<fieldset class="container-fluid">
					<legend>Información de identidad</legend>

					<div class="row form-group">
						<label for="primer_nombre" class="col-sm-2">Primer nombre</label>
						 <div class="col-sm-4">
							<input v-model="cliente.primer_nombre" type="text" name="primer_nombre" id="primer_nombre" class="form-control">
						</div>
						<label for="segundo_nombre" class="col-sm-2">Segundo nombre</label>
						 <div class="col-sm-4">
							<input v-model="cliente.segundo_nombre" type="text" name="segundo_nombre" id="segundo_nombre" class="form-control">
						</div>
					</div>
					<div class="row form-group">
						<label for="primer_apellido" class="col-sm-2">Primer apellido</label>
						 <div class="col-sm-4">
							<input v-model="cliente.primer_apellido" type="text" name="primer_apellido" id="primer_apellido" class="form-control">
						</div>
						<label for="segundo_apellido" class="col-sm-2">Segundo apellido</label>
						 <div class="col-sm-4">
							<input v-model="cliente.segundo_apellido" type="text" name="segundo_apellido" id="segundo_apellido" class="form-control">
						</div>
					</div>
					<div class="row form-group">
						<label for="cliente_rfc" class="col-sm-2">RFC</label>
						<div class="col-sm-4">
							<input v-model="cliente.rfc" type="text" name="cliente_rfc" id="cliente_rfc" class="form-control">
						</div>
						<label for="cliente_curp" class="col-sm-2">CURP</label>
						 <div class="col-sm-4">
							<input v-model="cliente.curp" type="text" name="cliente_curp" id="cliente_curp" class="form-control">
						</div>
					</div>
					<div class="row form-group">
						<label for="estado_civil" class="col-sm-2">Estado civil</label>
						 <div class="col-sm-4">
							<select v-model="cliente.estado_civil" name="estado_civil" id="estado_civil" class="form-control">
								<option v-for="estado_civil in catalogo_estado_civil" :key="estado_civil.id" :value="estado_civil.valor">{{ estado_civil.nombre }}</option>
							</select>
						</div>
						<label for="nacionalidad" class="col-sm-2">Nacionalidad</label>
						 <div class="col-sm-4">
							<input v-model="cliente.nacionalidad" type="text" name="nacionalidad" id="nacionalidad" class="form-control">
						</div>
					</div>

					<div class="row form-group">
						<label for="fecha_nacimiento" class="col-sm-2">Fecha nacimiento</label>
						 <div class="col-sm-4">
							<input v-model="cliente.fecha_nacimiento" type="date" name="fecha_nacimiento" id="fecha_nacimiento" class="form-control">
						</div>
						<label for="sexo" class="col-sm-2">Sexo</label>
						 <div class="col-sm-4">
							<select v-model="cliente.sexo" name="sexo" id="sexo" class="form-control">
								<option value="femenino">Femenino</option>
								<option value="masculino">Masculino</option>
							</select>
						</div>
					</div>

					<div class="row form-group">
						<label for="tipo_persona" class="col-sm-2">Tipo persona</label>
						<div class="col-sm-4">
							<select v-model="cliente.tipo_persona" name="tipo_persona" id="tipo_persona" class="form-control">
								<option value="1">Fisica</option>
								<option value="2">Fisica con actividad empresarial</option>
								<option value="3">Moral</option>
							</select>
						</div>
						<template v-if="cliente.tipo_persona == '3'">
						<label for="razon_social" class="col-sm-2">Razón social</label>
						<div class="col-sm-4">
							<input v-model="cliente.razon_social" type="text" name="razon_social" id="razon_social" class="form-control">
						</div>
						</template>
					</div>
				</fieldset>
				<div class="row">
					<div class="col-sm-12">
						<fieldset class="container-fluid">
							<legend>Datos de contacto</legend>

							<div v-for="(contacto,index) in cliente.contactos" :key="contacto.id" class="row form-group">
								<div class="col-sm-2">
									<label :for="'contacto_tipo_'+(index+1)" class="col-form-label">Tipo</label>
									<select v-model="contacto.tipo" :name="'contacto_tipo_'+(index+1)" :id="'contacto_tipo_'+(index+1)" class="form-control" @change="remover_validacion_contacto(contacto.id)" :readonly="contacto.block">
										<option v-for="info_tipo_contacto in catalogo_info_contacto" :key="info_tipo_contacto.id" :value="info_tipo_contacto.codigo">{{ info_tipo_contacto.nombre }}</option>
									</select>
								</div>
								<div class="col-sm-6">
									<label :for="'contacto_valor_'+(index+1)" class="col-form-label">Valor</label>
									<div class="input-group mb-2">

								        <div v-if="contacto.tipo != 'email'" class="input-group-prepend">
								          <div class="input-group-text">+52</div>
								        </div>

							        	<input v-model="contacto.valor" type="text" class="form-control"  :name="'contacto_valor_'+(index+1)" :id="'contacto_valor_'+(index+1)" @change="remover_validacion_contacto(contacto.id)" :readonly="contacto.block">
							        	
										<!-- <div v-if="contacto.tipo != 'telefono' && contacto.tipo != 'telefono_oficina'" class="input-group-append">
											<button :class="'btn '+(contacto.validado == 1 ? 'btn-secondary' : 'btn-danger')" :disabled="contacto.validado == 1" @click="enviar_otp(contacto.valor, contacto)">Enviar OTP</button>
										</div> -->

							    	</div>
								</div>
								<div class="col-sm-2 pt-4">
									<Radio v-model="contacto.principal" :id="'contacto_preferido_'+(index+1)" name="contacto_preferido" value="1" label="Método preferido"/>
								</div>
								<div v-if="false" class="col-sm-2 pt-4">
									<Radio v-model="contacto.facturas" :id="'contacto_facturas_'+(index+1)" name="contacto_facturas" value="1" label="Envir facturas" :disabled="contacto.tipo!='email'"/>
								</div>
								<div class="col-sm-2 d-flex justify-content-center">
									<div class="text-center mr-4">
										<button class="btn secondary-btn btn-icon" title="Guardar contacto" @click="guardar_cambios_contacto(contacto.id)">
											<span class="icon" title="Guardar cambios"><i class="gg-check"></i></span>
										</button>
									</div>
									<div class="text-center">
										<button class="btn complementary-btn btn-icon" title="Eliminar contacto" @click="eliminar_contacto(contacto.id)">
											<span class="icon icon-trash" title="Eliminar contacto"><i class="gg-trash-empty"></i></span>
										</button>
									</div>
								</div>
							</div>
							<div class="row mb-4">
								<div class="col-sm-2 offset-sm-10">
									<button class="btn complementary-btn" @click="nuevo_contacto_modal = true">Agregar contacto</button>
								</div>
							</div>
						</fieldset>
					</div>
				</div>
				<div class="row">
					<div class="col-sm-12">
						<fieldset>
							<legend>Direcciones</legend>

							<div class="accordion mb-4" id="listar_direcciones">
								<div v-for="(tmp_direccion,index) in cliente.direcciones" :key="tmp_direccion.id" class="card">
									<div class="card-header" id="headingOne">
										<h2 class="mb-0">
											<button class="btn btn-link" type="button" data-toggle="collapse" :data-target="'#collapse'+index" aria-expanded="true" :aria-controls="'collapse'+index">
												{{ tmp_direccion.calle }}
											</button>
										</h2>
									</div>

									<div :id="'collapse'+index" :class="'collapse'+(index==0?' show':'')" :aria-labelledby="'heading'+index" data-parent="#listar_direcciones">
										<div class="card-body">
											<div class="row form-group">
												<label for="direccion_calle" class="col-form-label col-sm-3">Calle</label>
												<div class="col-sm-9"><input v-model="tmp_direccion.calle" type="text" name="direccion_calle" :id="'direccion_calle'+index" class="form-control"></div>
											</div>
											<div class="row form-group">
												<label class="col-form-label col-sm-3">Número</label>
												<div class="col"><input v-model="tmp_direccion.no_interior" type="number" name="direccion_numero_interior" :id="'direccion_numero_interior'+index" class="form-control" placeholder="Número interior"></div>
												<div class="col"><input v-model="tmp_direccion.no_exterior" type="number" name="direccion_numero_exterior" :id="'direccion_numero_exterior'+index" class="form-control" placeholder="Número exterior"></div>
											</div>
											<div class="row form-group">
												<label for="direccion_colonia" class="col-form-label col-sm-3">Colonia</label>
												<div class="col-sm-9"><input v-model="tmp_direccion.colonia" type="text" name="direccion_colonia" :id="'direccion_colonia'+index" class="form-control"></div>
											</div>
											<div class="row form-group">
												<label for="direccion_delegacion_municipio" class="col-form-label col-sm-3">Delegación / Municipio</label>
												<div class="col-sm-9"><input v-model="tmp_direccion.delegacion" type="text" name="direccion_delegacion_municipio" :id="'direccion_delegacion_municipio'+index" class="form-control"></div>
											</div>
											<div class="row form-group">
												<label for="direccion_estado" class="col-form-label col-sm-3">Estado</label>
												<div class="col-sm-9"><input v-model="tmp_direccion.estado" type="text" name="direccion_estado" :id="'direccion_estado'+index" class="form-control"></div>
											</div>
											<div class="row form-group">
												<label for="direccion_cp" class="col-form-label col-sm-3">CP</label>
												<div class="col-sm-9"><input v-model="tmp_direccion.cp" type="number" name="direccion_cp" :id="'direccion_cp'+index" class="form-control"></div>
											</div>
											<div class="row form-group">
												<label for="direccion_principal" class="col-form-label col-sm-3">Dirección principal</label>
												<div class="col-sm-3">
													<BSwitch v-model="tmp_direccion.principal" :id="'direccion_principal'+index" name="direccion_principal" texton="Sí" textoff="No" />
												</div>
												<label for="direccion_correspondecia" class="col-form-label col-sm-3">Dirección correspondencia</label>
												<div class="col-sm-3">
													<BSwitch v-model="tmp_direccion.correspondencia" :id="'direccion_correspondecia'+index" name="direccion_correspondecia" texton="Sí" textoff="No" />
												</div>
											</div>
											<div class="row form-group">
												<div class="col-sm-2 offset-sm-8"><button class="btn secondary-btn" @click="guardar_cambios_direccion(tmp_direccion.id)">Guardar cambios</button></div>
												<div class="col-sm-2"><button class="btn complementary-btn" @click="eliminar_direccion(tmp_direccion.id,tmp_direccion.principal)">Eliminar</button></div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="row mb-4">
								<div class="col-sm-2 offset-sm-10">
									<button class="btn complementary-btn" @click="direccion_nueva.mostrar = true">Agregar dirección</button>
								</div>
							</div>
						</fieldset>
					</div>
				</div>
			</div>
			<div class="footer">
				<div class="row">

					<div class="col-sm-2 offset-sm-8">
						<button class="btn principal-btn" @click="guardar_cambios">Guardar</button>
					</div>
					<div class="col-sm-2">
						<button class="btn danger-btn" @click="cerrar_detalles">Cerrar</button>
					</div>
				</div>
			</div>
		</Modal>

		<Modal v-if="cliente && ver_creditos" :options="{width: '95vw',close:true}" @close="ver_creditos = false">
			<div class="title">Créditos</div>
			<div class="body">
				<fieldset class="container-fluid">
					<legend>Créditos</legend>
					<div class="row mt-3">
						<div class="col-sm-4">
							<ul class="nav nav-tabs">
								<li v-for="(credito,index) in cliente.creditos" :key="credito.id" class="nav-item">
									<span :class="'nav-link'+(credito_seleccionado.id == credito.id ? ' active' : '')" @click="cambiar_credito(credito.id)">{{ 'Crédito '+(index+1) }}</span>
								</li>
							</ul>
						</div>
						<div class="col-sm-2">
							<button class="btn complementary-btn" @click="ver_garantias = true">Ver garantias</button>
						</div>
						<div class="col-sm-2">
							<button class="btn complementary-btn" @click="ver_valijas = true">Ver valijas</button>
						</div>
						<div class="col-sm-2">
							<button class="btn complementary-btn" @click="modal_reestructurar_credito=true">Reestrucutrar Crédito</button>
						</div>
						<div class="col-sm-2">
							<button :class="'btn '+(credito_seleccionado.avatar_estatus == 0 ? 'warning-btn' : 'complementary-btn')" :disabled="credito_seleccionado.avatar_estatus != 0" @click="enviar_entrevista">{{ credito_seleccionado.avatar_estatus == 0 ? 'Enviar al avatar' : 'Entrevista aún no finalizada' }}</button>
						</div>
						<div v-if="false" class="col-sm-2">
							<button class="btn complementary-btn" @click="ver_facturas = true">Ver facturas/estados de cuenta</button>
						</div>
					</div>
					<div class="row form-group mt-3">
						<label for="no_credito" class="col-form-label col-sm-2">No Crédito</label>
						<div class="col-sm-4">
							<input :value="credito_seleccionado.no_credito+' - '+info_pago.contractFolio" type="text" name="no_credito" id="no_credito" class="form-control" readonly>
						</div>
						<label for="importe_solicitado" class="col-form-label col-sm-2">Importe solicitado</label>
						<div class="col-sm-4">
							<input v-model="importe_solicitado_format" type="text" name="importe_solicitado" id="importe_solicitado" class="form-control" readonly>
						</div>
					</div>
					<div class="row form-group">
						<label for="importe_seleccionado" class="col-form-label col-sm-2">Importe seleccionado</label>
						<div class="col-sm-4">
							<input v-model="importe_seleccionado_format" type="text" name="importe_seleccionado" id="importe_seleccionado" class="form-control" readonly>
						</div>
						<label for="importe_pre_autorizado" class="col-form-label col-sm-2">Importe pre-autorizado</label>
						<div class="col-sm-4">
							<input v-model="importe_pre_autorizado_format" type="text" name="importe_pre_autorizado" id="importe_pre_autorizado" class="form-control" readonly>
						</div>
					</div>
					<div class="row form-group">
						<label for="acepto_importe_pre_autorizado" class="col-form-label col-sm-2">Acepto importe pre-autorizado</label>
						<div class="col-sm-4">
							<BSwitch v-model="credito_seleccionado.acepto_importe_pre_autorizado" name="acepto_importe_pre_autorizado" id="acepto_importe_pre_autorizado" texton="Sí" textoff="No" disabled="1"/>
						</div>
						<label for="plazo" class="col-form-label col-sm-2">Plazos</label>
						<div class="col-sm-4">
							<input v-model="credito_seleccionado.plazo" type="text" name="plazo" id="plazo" class="form-control" readonly>
						</div>
					</div>
					<div class="row form-group">
						<label for="descripcion_solicitud" class="col-form-label col-sm-2">Descrición solicitud</label>
						<div class="col-sm-4">
							<input v-model="credito_seleccionado.descripcion_solicitud" type="text" name="descripcion_solicitud" id="descripcion_solicitud" class="form-control" readonly>
						</div>
						<label for="promotor_id" class="col-form-label col-sm-2">Promotor ID</label>
						<div class="col-sm-4">
							<input v-model="credito_seleccionado.promotor_id" type="text" name="promotor_id" id="promotor_id" class="form-control" readonly>
						</div>
					</div>
					<div class="row form-group">
						<label for="acepta_politicas_privacidad" class="col-form-label col-sm-2">Acepta politicas de privacidad</label>
						<div class="col-sm-4">
							<BSwitch v-model="credito_seleccionado.acepta_politicas_privacidad" name="acepta_politicas_privacidad" id="acepta_politicas_privacidad" texton="Sí" textoff="No" disabled="1"/>
						</div>
						<label for="descripcion_giro_negocio" class="col-form-label col-sm-2">Descripción giro negocio</label>
						<div class="col-sm-4">
							<input v-model="credito_seleccionado.descripcion_giro_negocio" type="text" name="descripcion_giro_negocio" id="descripcion_giro_negocio" class="form-control" readonly>
						</div>
					</div>
					<div class="row form-group">
						<label for="descripcion_politicas" class="col-form-label col-sm-2">Descripción politicas</label>
						<div class="col-sm-4">
							<input v-model="credito_seleccionado.descripcion_politicas" type="text" name="descripcion_politicas" id="descripcion_politicas" class="form-control" readonly>
						</div>
						<label for="destino_credito_ejercido" class="col-form-label col-sm-2">Destino crédito ejercido</label>
						<div class="col-sm-4">
							<input v-model="credito_seleccionado.destino_credito_ejercido" type="text" name="destino_credito_ejercido" id="destino_credito_ejercido" class="form-control" readonly>
						</div>
					</div>
				</fieldset>
				<div class="row">
					<div class="col-sm-8">
						<fieldset class="container-fluid">
							<legend>Observaciones en originación</legend>
							<div class="row">
								<div class="col-sm-12 table-header">
									<table class="table table-striped table-observaciones">
										<thead>
											<th>Usuario</th>
											<th>Fecha</th>
											<th>Modulo</th>
											<th>Observación</th>
										</thead>
									</table>
								</div>
							</div>
							<div class="row">
								<div class="col-sm-12 table-container">
									<table class="table table-striped table-observaciones">
										<tbody>
											<tr v-for="(observacion, observacionIndex) in credito_seleccionado.observaciones" :key="observacionIndex">
												<td>{{ observacion.name }}</td>
												<td>{{ $moment(observacion.created_at).format('YYYY-MM-DD HH:mm:ss') }}</td>
												<td>{{ observacion.modulo }}</td>
												<td>{{ observacion.observaciones }}</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</fieldset>
					</div>
					<div class="col-sm-4">
						<fieldset class="container-fluid">
							<legend>Información de pago</legend>
							<div class="row form-group">
								<label for="" class="col-form-label col-sm-4">Monto a pagar</label>
								<div class="col-sm-8">
									<input v-model="monto_pagar" type="text" name="" id="" class="form-control" readonly>
								</div>
							</div>
							<div class="row form-group">
								<label for="" class="col-form-label col-sm-4">Fecha corte</label>
								<div class="col-sm-8">
									<input v-model="info_pago.creditCutoffDate" type="text" name="" id="" class="form-control" readonly>
								</div>
							</div>
							<div class="row form-group">
								<label for="" class="col-form-label col-sm-4">Fecha límite pago</label>
								<div class="col-sm-8">
									<input v-model="info_pago.creditDueDate" type="text" name="" id="" class="form-control" readonly>
								</div>
							</div>
							<div class="row form-group">
								<label for="" class="col-form-label col-sm-4">Cuenta bancaria</label>
								<div class="col-sm-8">
									<input v-model="info_pago.bankAccountNumber" type="text" name="" id="" class="form-control" readonly>
								</div>
							</div>
							<div class="row form-group">
								<label for="" class="col-form-label col-sm-4">Referencia</label>
								<div class="col-sm-8">
									<input v-model="pago_referencia" type="text" name="" id="" class="form-control" readonly>
								</div>
							</div>
							<div class="row form-group">
								<label for="" class="col-form-label col-sm-4">Fecha último pago</label>
								<div class="col-sm-8">
									<input v-model="info_pago.creditLastPayment" type="text" name="" id="" class="form-control" readonly>
								</div>
							</div>
						</fieldset>
					</div>
				</div>
			</div>
		</Modal>

		<Modal v-if="cliente && ver_garantias" :options="{close:true}" @close="ver_garantias = false">
			<div class="title">Garantias</div>
			<div class="body">
				<fieldset class="container-fluid">
					<legend>Garantias</legend>

					<ul class="nav nav-tabs mb-3">
						<li v-for="(tmpGarantia, index) in credito_seleccionado.garantias" :key="tmpGarantia.id" class="nav-item">
							<span :class="'nav-link'+(tmpGarantia.id == garantia.id ? ' active' : '')" @click="cargar_garantia(tmpGarantia)">{{ 'Garantia '+(index+1) }}</span>
						</li>
					</ul>

					<div class="row form-group">
						<label for="garantia.tipogarantia_id" class="col-form-label col-sm-2">Tipo garantia</label>
						<div class="col-sm-4">
							<input v-model="garantia.tipo_garantia" type="text" name="garantia.tipogarantia_id" id="garantia.tipogarantia_id" class="form-control" readonly>
						</div>
						<label for="garantia.descripcion" class="col-form-label col-sm-2">Descripción</label>
						<div class="col-sm-4">
							<input v-model="garantia.descripcion" type="text" name="garantia.descripcion" id="garantia.descripcion" class="form-control" readonly>
						</div>
					</div>
					<div class="row form-group">
						<label for="garantia.propia" class="col-form-label col-sm-2">Propia</label>
						<div class="col-sm-4">
							<BSwitch v-model="garantia.propia" name="garantia.propia" id="garantia.propia" texton="Sí" textoff="No" disabled="1"/>
						</div>
						<label for="garantia.calle" class="col-form-label col-sm-2">Calle</label>
						<div class="col-sm-4">
							<input v-model="garantia.calle" type="text" name="garantia.calle" id="garantia.calle" class="form-control" readonly>
						</div>
					</div>
					<div class="row form-group">
						<label for="garantia.colonia" class="col-form-label col-sm-2">Colonia</label>
						<div class="col-sm-4">
							<input v-model="garantia.colonia" type="text" name="garantia.colonia" id="garantia.colonia" class="form-control" readonly>
						</div>
						<label for="garantia.cp" class="col-form-label col-sm-2">CP</label>
						<div class="col-sm-4">
							<input v-model="garantia.cp" type="text" name="garantia.cp" id="garantia.cp" class="form-control" readonly>
						</div>
					</div>
					<div class="row form-group">
						<label for="garantia.delegacion" class="col-form-label col-sm-2">Delegación</label>
						<div class="col-sm-4">
							<input v-model="garantia.delegacion" type="text" name="garantia.delegacion" id="garantia.delegacion" class="form-control" readonly>
						</div>
						<label for="garantia.entidad_id" class="col-form-label col-sm-2">Entidad</label>
						<div class="col-sm-4">
							<input v-model="garantia.entidad" type="text" name="garantia.entidad_id" id="garantia.entidad_id" class="form-control" readonly>
						</div>
					</div>
					<div class="row form-group">
						<label for="garantia.valor" class="col-form-label col-sm-2">Valor</label>
						<div class="col-sm-4">
							<input v-model="garantia_valor_format" type="text" name="garantia.valor" id="garantia.valor" class="form-control" readonly>
						</div>
						<label for="garantia.status" class="col-form-label col-sm-2">Estatus</label>
						<div class="col-sm-4">
							<input v-model="garantia.status" type="text" name="garantia.status" id="garantia.status" class="form-control" readonly>
						</div>
					</div>
				</fieldset>
			</div>
		</Modal>

		<Modal v-if="cliente && ver_valijas" :options="{close:true}" @close="ver_valijas = false">
			<div class="title">Valijas</div>
			<div class="body">
				<fieldset class="container-fluid">
					<legend>Valijas</legend>

					<ul class="nav nav-tabs mb-3">
						<li v-for="(tmpValija, index) in credito_seleccionado.valijas" :key="tmpValija.id" class="nav-item">
							<span :class="'nav-link'+(tmpValija.id == valija.id ? ' active' : '')" @click="cargar_valija(tmpValija)">{{ 'Valija '+(index+1) }}</span>
						</li>
					</ul>

					<div v-if="valija">
						<div class="row form-group">
							<label for="valija.valija_id" class="col-form-label col-sm-2">Valija ID</label>
							<div class="col-sm-4">
								<input v-model="valija.valija_id" type="text" name="valija.valija_id" id="valija.valija_id" class="form-control" readonly>
							</div>
							<label for="valija.pendiente" class="col-form-label col-sm-2">Pendiente</label>
							<div class="col-sm-4">
								<BSwitch v-model="valija.pendiente" name="garantia.pendiente" id="garantia.pendiente" texton="Sí" textoff="No" disabled="1"/>
							</div>
						</div>
						<div class="row form-group">
							<label for="valija.tag" class="col-form-label col-sm-2">Tag</label>
							<div class="col-sm-4">
								<input v-model="valija.tag" type="text" name="valija.tag" id="valija.tag" class="form-control" readonly>
							</div>
							<label for="valija.nombre" class="col-form-label col-sm-2">Nombre</label>
							<div class="col-sm-4">
								<input v-model="valija.nombre" type="text" name="valija.nombre" id="valija.nombre" class="form-control" readonly>
							</div>
						</div>
						<div class="row form-group">
							<label for="valija.descripcion" class="col-form-label col-sm-2">Descrición</label>
							<div class="col-sm-10">
								<textarea class="form-control" readonly>{{ valija.descripcion }}</textarea>
							</div>
						</div>
					</div>
					<div v-else>
						<div class="row form-group">
							<div class="col-sm-12 text-center">Esta solicitud no tiene valijas</div>
						</div>
					</div>
				</fieldset>
			</div>
		</Modal>

		<Modal v-if="cliente && ver_observaciones" :options="{width: '75vw',close:true}" @close="ver_observaciones = false">
			<div class="title">Observaciones</div>
			<div class="body">
				<fieldset class="container-fluid">
					<legend>Observaciones</legend>
					<div class="row">
						<div class="col-sm-12">
							<table class="table table-striped mb-0 table-cliente-observaciones">
								<thead>
									<th>Usuario</th>
									<th>Fecha</th>
									<th>Observación</th>
								</thead>
							</table>
						</div>
					</div>
					<div class="row mb-3">
						<div class="col-sm-12 table-observaciones-container">
							<table class="table table-striped table-cliente-observaciones">
								<tbody  v-if="cliente.observaciones.length > 0">
									<tr v-for="observacion in cliente.observaciones" :key="observacion.id">
										<td>{{ observacion.nombre }}</td>
										<td>{{ $moment(observacion.created_at).format('YYYY-MM-DD HH:mm:ss') }}</td>
										<td>{{ observacion.observacion }}</td>
									</tr>
								</tbody>
								<tbody v-else>
									<tr>
										<td colspan="3">No hay observaciones</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
					<div class="row form-group">
						<div class="col-sm-10">
							<input v-model="nueva_observacion" type="text" name="" id="" class="form-control" placeholder="Nueva observación" @keyup="enviar_observacion_enter">
						</div>
						<div class="col-sm-2">
							<button class="btn secondary-btn" @click="enviar_observacion">Enviar</button>
						</div>
					</div>
				</fieldset>
			</div>
		</Modal>

		<Modal v-if="nuevo_contacto_modal" @close="cancelar_nuevo_contacto">
			<div class="title">Agregar dato de contacto</div>
			<div class="body">
				<div class="row form-group">
					<div class="col-sm-3">
						<label for="nuevo_contacto_tipo">Selecciona el tipo de contacto</label>
						<select v-model="nuevo_contacto.tipo" name="nuevo_contacto_tipo" id="nuevo_contacto_tipo" class="form-control" :disabled="nuevo_contacto.validado">
							<option v-for="tipo_contacto in catalogo_info_contacto" :key="tipo_contacto.id" :value="tipo_contacto.codigo">{{ tipo_contacto.nombre }}</option>
						</select>
					</div>
					<div class="col-sm-5">
						<label for="nuevo_contacto_valir">Valor</label>
						<div class="input-group mb-2">
					        <div v-if="nuevo_contacto.tipo != 'email'" class="input-group-prepend">
					          <div class="input-group-text">+52</div>
					        </div>
				        	<input v-model="nuevo_contacto.valor" type="text" class="form-control"  name="nuevo_contacto_valir" id="nuevo_contacto_valir" :disabled="nuevo_contacto.validado">
				        	<!-- <div v-if="nuevo_contacto.tipo != 'telefono' && nuevo_contacto.tipo != 'telefono_oficina'" class="input-group-append">
								<button :class="'btn '+(!nuevo_contacto.validado ? 'btn-danger' : 'btn-secondary')" :disabled="nuevo_contacto.validado" @click="enviar_otp(nuevo_contacto.valor)">Enviar OTP</button>
							</div> -->
				    	</div>
					</div>
					<div class="col-sm-2">
						<label for="nuevo_contacto_preferido" class="col-form-label">Medio preferido</label>
						<div class="custom-control custom-switch">
							<input v-model="nuevo_contacto.preferido" type="checkbox" name="nuevo_contacto_preferido" id="nuevo_contacto_preferido" class="custom-control-input">
							<label for="nuevo_contacto_preferido" class="custom-control-label" style="color: #B3B3B3">No | Sí</label>
						</div>
					</div>
					<div class="col-sm-2">
						<label for="nuevo_contacto_facturas" class="col-form-label">Enviar facturas</label>
						<div class="custom-control custom-switch">
							<input v-model="nuevo_contacto.facturas" type="checkbox" name="nuevo_contacto_facturas" id="nuevo_contacto_facturas" class="custom-control-input" :disabled="nuevo_contacto.tipo != 'email'">
							<label for="nuevo_contacto_facturas" class="custom-control-label" style="color: #B3B3B3">No | Sí</label>
						</div>
					</div>
				</div>
			</div>
			<div class="footer">
				<div class="row">
					<div class="col-sm-2 offset-sm-8">
						<button class="btn principal-btn" @click="guardar_nuevo_contacto">Agregar</button>
					</div>
					<div class="col-sm-2">
						<button class="btn danger-btn" @click="cancelar_nuevo_contacto">Cancelar</button>
					</div>
				</div>
			</div>
		</Modal>



		<Modal v-if="validar_otp_modal" :options="{width: '30vw'}" @close="cancelar_validar_otp">
			<div class="title">Validar OTP</div>
			<div class="body">
				<div class="row">
					<div class="col-sm-12">
						<input v-model="otp.codigo" type="text" name="codigo_otp" id="codigo_otp" class="form-control">
					</div>
				</div>
			</div>
			<div class="footer">
				<div class="row">
					<div class="col-sm-3 offset-sm-6">
						<button class="btn principal-btn" @click="validar_otp">Validar</button>
					</div>
					<div class="col-sm-3">
						<button class="btn danger-btn" @click="cancelar_validar_otp">Cancelar</button>
					</div>
				</div>
			</div>
		</Modal>

		<Modal v-if="ver_facturas" :options="{close: true}" @close="cancelar_ver_facturas">
			<div class="title">Facturas / Estados de cuenta</div>
			<div class="body">
				<DataGrid :config="dataGridConfigFacturas" :data="facturas" :select="seleccionarFacturas" @actions="dataGridActionsFacturas" @ready="buscar_botones_facturas">
					<div class="col-sm-2"><button class="btn warning-btn" @click="obtener_facturas">Recargar</button></div>
				</DataGrid>
			</div>
			<div class="footer"></div>
		</Modal>

		<Modal v-if="reenviar_factura_modal" :options="{width: '30vw'}" @close="cancelar_reenviar_factura">
			<div class="title">Reenviar factura/estado de cuenta</div>
			<div class="body">
				<div class="row form-group">
					<label for="email_reenviar" class="col-form-label col-sm-2">Email</label>
					<div class="col-sm-10">
						<input v-model="reenviar_factura.email" class="form-control" type="text" name="email_reenviar" id="email_reenviar">
					</div>
				</div>
			</div>
			<div class="footer">
				<div class="row">
					<div class="col-sm-3 offset-sm-6">
						<button class="btn secondary-btn" @click="reenviar_factura_peticion">Enviar</button>
					</div>
					<div class="col-sm-3">
						<button class="btn danger-btn" @click="cancelar_reenviar_factura">Cancelar</button>
					</div>
				</div>
			</div>
		</Modal>

		<Modal v-if="ver_metodos_validacion" :options="{width: '30vw'}" @close="cancelar_validacion">
			<div class="title">Solicitar acceso</div>
			<div class="body">
				<div class="row form-group">
					<div class="col-sm-12 text-center">Selecciona un metodo de validación</div>
				</div>
				<div v-for="(metodo_validacion, index) in metodos_validacion" class="row form-group">
					<div class="custom-control custom-switch ml-4">
						<input type="checkbox" class="custom-control-input" data-tipo="solicitar_acceso_valor" :name="'tipo_contacto_'+index" :id="'tipo_contacto_'+index" :checked="index == 0" @click="seleccion_unico_metodo_validacion($event, metodo_validacion.valor)" :value="metodo_validacion.valor">
						<label :for="'tipo_contacto_'+index" class="custom-control-label"></label>
					</div>
					<div class="col-sm-10">
						<input type="text" :name="'metodo_contacto'+index" :id="'metodo_contacto'+index" class="form-control" readonly="true" :value="ocultar_valor(metodo_validacion.valor)">
					</div>
				</div>
				<div class="row form-group">
					<div class="custom-control custom-switch ml-4">
						<input type="checkbox" class="custom-control-input" data-tipo="solicitar_acceso_valor" name="tipo_contacto_sistema" id="tipo_contacto_sistema" @click="seleccion_unico_metodo_validacion($event, 'sistema')" value="sistema">
						<label for="tipo_contacto_sistema" class="custom-control-label"></label>
					</div>
					<div class="col-sm-10">
						<input type="text" name="'metodo_contacto_sistema" id="metodo_contacto_sistema" class="form-control" readonly="true" value="Sistema">
					</div>
				</div>
				<div v-if="otp_validacion.destinatario == 'sistema'" class="row form-group">
					<div class="col-sm-12">
						<label for="metodo_contacto_acceso">Razón de acceso</label>
						<textarea v-model="otp_validacion.razones" style="resize: none;" name="'metodo_contacto_acceso" id="metodo_contacto_acceso" class="form-control"></textarea>
					</div>
				</div>
			</div>
			<div class="footer">
				<div class="row">
					<div class="col-sm-4 offset-sm-5">
						<button class="btn principal-btn" @click="enviar_codigo_acceso">Enviar validación</button>
					</div>
					<div class="col-sm-3">
						<button class="btn danger-btn" @click="cancelar_validacion">Cancelar</button>
					</div>
				</div>
			</div>
		</Modal>

		<Modal v-if="ver_validar_codigo_modal" :options="{width: '30vw'}" @close="cancelar_validacion_codigo">
			<div class="title">Validar código</div>
			<div class="body">
				<div class="row form-group">
					<label for="validar_acceso_codigo" class="col-form-label col-sm-2">Código</label>
					<div class="col-sm-10">
						<input v-model="otp_validacion.codigo" type="text" name="validar_acceso_codigo" id="validar_acceso_codigo" class="form-control">
					</div>
				</div>
			</div>
			<div class="footer">
				<div class="row form-group">
					<div class="col-sm-3 offset-sm-6"><button class="btn secondary-btn" @click="validar_codigo_acceso">Validar</button></div>
					<div class="col-sm-3"><button class="btn danger-btn" @click="cancelar_validacion_codigo">Cancelar</button></div>
				</div>
			</div>
		</Modal>

		<Modal v-if="direccion_nueva.mostrar" :options="{width: '40vw'}" @close="cancelar_direccion_nueva">
			<div class="title">Agregar dirección</div>
			<div class="body">
				<div class="row form-group">
					<label for="" class="col-form-label col-sm-3">Origen de la dirección</label>
					<div class="col-sm-9 pt-2">
						<Radio v-model="direccion_nueva_origen" id="direccion_nueva_origen_manual" name="direccion_nueva_origen" value="manual" label="Manual" :other="{display:'inline'}"/>
						<Radio v-model="direccion_nueva_origen" id="direccion_nueva_origen_garantia" name="direccion_nueva_origen" value="garantia" label="Garantia" :other="{display:'inline'}"/>
					</div>
				</div>
				<div v-if="direccion_nueva_origen == 'garantia'" class="row form-group">
					<label for="lista_garantias" class="col-form-label col-sm-3">Garantia</label>
					<div class="col-sm-9">
						<select v-model="direccion_nueva_seleccionada" name="lista_garantias" id="lista_garantias" class="form-control">
							<option value="default">Selecciona la garantia</option>
							<option v-for="garantia in listar_garantias" :key="garantia.id" :value="garantia.id">{{ garantia.calle }}</option>
						</select>
					</div>
				</div>
				<div class="row form-group">
					<label for="direccion_nueva_calle" class="col-form-label col-sm-3">Calle</label>
					<div class="col-sm-9"><input v-model="direccion_nueva.calle" type="text" name="direccion_nueva_calle" id="direccion_nueva_calle" class="form-control"></div>
				</div>
				<div class="row form-group">
					<label for="" class="col-form-label col-sm-3">Número</label>
					<div class="col-sm-9">
						<div class="row">
							<div class="col"><input v-model="direccion_nueva.no_interior" type="text" name="direccion_nueva_numero_interior" id="direccion_nueva_numero_interior" class="form-control" placeholder="Número interior"></div>
							<div class="col"><input v-model="direccion_nueva.no_exterior" type="text" name="direccion_nueva_numero_exterior" id="direccion_nueva_numero_exterior" class="form-control" placeholder="Número exterior"></div>
						</div>
					</div>
				</div>
				<div class="row form-group">
					<label for="direccion_nueva_colonia" class="col-form-label col-sm-3">Colonia</label>
					<div class="col-sm-9"><input v-model="direccion_nueva.colonia" type="text" name="direccion_nueva_colonia" id="direccion_nueva_colonia" class="form-control"></div>
				</div>
				<div class="row form-group">
					<label for="direccion_nueva_delegacion" class="col-form-label col-sm-3">Delegación/Municipio</label>
					<div class="col-sm-9"><input v-model="direccion_nueva.delegacion" type="text" name="direccion_nueva_delegacion" id="direccion_nueva_delegacion" class="form-control"></div>
				</div>
				<div class="row form-group">
					<label for="direccion_nueva_estado" class="col-form-label col-sm-3">Estado</label>
					<div class="col-sm-9"><input v-model="direccion_nueva.estado" type="text" name="direccion_nueva_estado" id="direccion_nueva_estado" class="form-control"></div>
				</div>
				<div class="row form-group">
					<label for="direccion_nueva_cp" class="col-form-label col-sm-3">CP</label>
					<div class="col-sm-9"><input v-model="direccion_nueva.cp" type="text" name="direccion_nueva_cp" id="direccion_nueva_cp" class="form-control"></div>
				</div>
				<div class="row form-group">
					<label for="direccion_nueva_principal" class="col-form-label col-sm-3">Dirección principal</label>
					<div class="col-sm-3">
						<BSwitch v-model="direccion_nueva.principal" id="direccion_nueva_principal" name="direccion_nueva_principal" texton="Sí" textoff="No" />
					</div>
					<label for="direccion_nueva_correspondencia" class="col-form-label col-sm-3">Dirección correspondencia</label>
					<div class="col-sm-3">
						<BSwitch v-model="direccion_nueva.correspondencia" id="direccion_nueva_correspondencia" name="direccion_nueva_correspondencia" texton="Sí" textoff="No" />
					</div>
				</div>
			</div>
			<div class="footer">
				<div class="row">
					<div class="col-sm-2 offset-sm-8"><button class="btn secondary-btn" @click="guardar_direccion_nueva">Agregar</button></div>
					<div class="col-sm-2"><button class="btn danger-btn" @click="cancelar_direccion_nueva">Cancelar</button></div>
				</div>
			</div>
		</Modal>
		<ReestructurarCredito v-if="modal_reestructurar_credito" @close="modal_reestructurar_credito=false" :informacion_cliente="cliente" @update="actualizar_informacion"/>
	</div>
</template>

<script type="text/javascript">
	import DataGrid from '@/components/DataGridV2'
	import Modal from '@/components/Modal'
	import Radio from '@/components/Form/Radio'
	import BSwitch from '@/components/Form/Switch'

	import api from '@/apps/clientes/api/clientes'
	import apiAccount from '@/apps/clientes/api/account'
	import ReestructurarCredito from "./Solicitud/ReestructurarCredito";

	export default {
		components: {
			DataGrid, Modal, Radio, BSwitch, ReestructurarCredito
		}
		,data: function() {
			return {
				dataGridConfig: {
					name: 'clientes'
					,cols: {
						nombre_completo: 'Cliente'
						,rfc: 'RFC'
						,curp: 'Curp'
						,created_at: 'Fecha creación'
					}
					,paginator: {
						pagina_actual: 1
						,total_registros: 1
						,registros_por_pagina: 20
					}
					,mutators: {
						"contactos.0.valor": function(val) {
							return val.indexOf('@') != -1 ? '**********@'+val.split('@')[1] : '*******'+val.substr(6);
						}
						,rfc: function(val) {
							return val ? val.substr(0,6)+'*******' : val;
						}
						,curp: function(val) {
							return val ? val.substr(0,6)+'**********' : val;
						}
						,created_at: function(val, row, vue) {
							return vue.$moment(val).format('YYYY-MM-DD HH:mm:ss');
						}
					}
				}
				,options: {
					page: 1
					,order_col: 'id'
					,order_dir: 'desc'
					,limit: 20
					,filters: []
				}
				,dataGridConfigFacturas: {
					name: 'clientes'
					,cols: {
						receptor_nombre: 'Nombre'
						,receptor_rfc: 'RFC'
						,total: 'Total'
						,fecha_emision: 'Fecha emisión'
						,id: {
							text: 'Reenviar'
							,ordeable: false
						}
					}
					,paginator: {
						pagina_actual: 1
						,total_registros: 1
						,registros_por_pagina: 20
					}
					,mutators: {
						id: function(val) {
							return '<button class="btn principal-btn btn-facturas" data-id="'+val+'">Reenviar</button>';
						}
					}
					,select_items: false
				}
				,optionsFacturas: {
					page: 1
					,order_col: 'id'
					,order_dir: 'desc'
					,limit: 20
					,filters: []
				}
				,seleccionar: false
				,seleccionadas: []
				,clientes: []
				,cliente: {
					direcciones: []
				}
				,cliente_org: null
				,ver_detalles_cliente: false
				,credito_seleccionado: null
				,garantia: null
				,valija: {
					valija_id: null
					,pendiente: null
					,tag: null
					,nombre: null
					,descripcion: null
				}
				,ver_creditos: false
				,ver_observaciones: false
				,ver_garantias: false
				,ver_ejercido: false
				,ver_valijas: false
				,ver_facturas: false
				,catalogo_info_contacto: []
				,nueva_observacion: null
				,nuevo_contacto_modal: false
				,nuevo_contacto: {
					tipo: 'email'
					,descripcion: null
					,preferida: null
					,facturas: null
					,validado: false
				}
				,validar_otp_modal: false
				,otp: {
					destinatario: null
					,codigo: null
					,contacto: null
				}
				,emailPattern: /^\w+([\.-\\+]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
				,phonePattern: /^([0-9]){10}$/
				,otpPattern: /^([0-9]){8}$/
				,facturas:[]
				,seleccionarFacturas: false
				,seleccionadasFacturas: []
				,eventos_anclados: false
				,reenviar_factura_modal: false
				,reenviar_factura: {
					id: null
					,email: null
				}
				,ver_metodos_validacion: false
				,cliente_temporal: null
				,metodos_validacion: []
				,ver_validar_codigo_modal: false
				,otp_validacion: {
					destinatario: null
					,codigo: null
				}
				,nuevo_contacto_modal: false
				,nuevo_metodo_contacto: {
					tipo: 'email'
					,valor: null
					,validado: 0
					,principal: 0
					,facturas: 0
				}
				,info_pago: null
				,direccion_nueva: {
					mostrar: false
					,calle: null
					,no_interior: null
					,no_exterior: null
					,colonia: null
					,delegacion: null
					,estado: null
					,cp: null
					,principal: 0
				}
				,direccion_nueva_origen: 'manual'
				,direccion_nueva_seleccionada: 'default'
				,catalogo_estado_civil: []
				,modal_reestructurar_credito:false
				// ,modal_creditos_disponibles:false
				// ,creadito_a_reestructurar:null,
			}
		}
		,mounted: function() {
			this.obtener_catalogo_info();
			// this.actualizar_informacion();
		}
		,methods: {
			obtener_clientes: async function() {
				let res = (await api.listar_usuarios(this.options)).data;

				this.clientes = res.data;
				this.dataGridConfig.paginator.pagina_actual = res.current_page;
				this.dataGridConfig.paginator.total_registros = res.total;
				this.dataGridConfig.paginator.registros_por_pagina = parseInt(res.per_page);
			}
			,obtener_facturas: async function() {
				let res = (await api.listar_facturas(this.optionsFacturas, this.credito_seleccionado.no_credito)).data;

				this.facturas = res.data;
				this.dataGridConfigFacturas.paginator.pagina_actual = res.current_page;
				this.dataGridConfigFacturas.paginator.total_registros = res.total;
				this.dataGridConfigFacturas.paginator.registros_por_pagina = parseInt(res.per_page);
			}
			,obtener_catalogo_info: async function() {
				// this.$log.info('seleccionadas mounted', this.seleccionadas);
				this.catalogo_info_contacto = (await api.catalogos_obtener('metodo_contacto')).data.opciones;
				this.catalogo_estado_civil = (await api.catalogos_obtener('estado_civil')).data.opciones;
			}
			,dataGridActions: function(tipo, data) {
				// this.$log.info('tipo', tipo);
				if (tipo == 'options') {
					this.options = data;
					this.obtener_clientes();
				}else {
					this.seleccionadas = data;
					this.seleccionar = true;
				}
			}
			,dataGridActionsFacturas: function(tipo, data) {
				// this.$log.info('tipo', tipo);
				if (tipo == 'options') {
					this.optionsFacturas = data;
					this.obtener_facturas();
				}else {
					this.seleccionadasFacturas = data;
					this.seleccionarFacturas = true;
				}
			}
			,cerrar_detalles: function() {
				this.credito_seleccionado = null;
				this.cliente = null;
				this.ver_detalles_cliente = false;
				this.seleccionar = false;
			}
			,ver_detalles: async function(id) {
				if (this.seleccionadas.length == 0)
					return this.$helper.showMessage('Error','Tienes que seleccionar un cliente para ver en detalle','error','alert');

				try {

					let res = (await api.detalle_usuario(id)).data;
					this.cliente = res;
					// this.$log.info('información cliente', res);
					this.cliente_org = this.$helper.clone( res);
					this.credito_seleccionado = this.cliente.creditos[0];
					this.garantia = this.credito_seleccionado.garantias[0];

					// this.$log.info('credito', this.credito_seleccionado);
					this.info_pago = (await api.obtener_informacion_pago(this.credito_seleccionado.no_credito)).data;

					if (this.credito_seleccionado.valijas.length > 0)
						this.valija = this.credito_seleccionado.valijas[0];

					this.ver_detalles_cliente = true;
				}catch(e) {
					this.$log.info('error', e);
					this.$helper.showAxiosError(e,'Error');
				}
			}
			,cambiar_credito: async function(id) {
				let encontrado = false;
				for(let i=0; i<this.cliente.creditos.length; i++) {
					if (this.cliente.creditos[i].id == id) {
						this.credito_seleccionado = this.cliente.creditos[i];
						encontrado = true;
						i=this.cliente.creditos.length;
					}
				}

				if (!encontrado)
					return this.$helper.showMessage('Error','El ID del crédito indicado no existe','error','alert')

				this.info_pago = (await api.obtener_informacion_pago(this.credito_seleccionado.no_credito)).data;
			}
			,cargar_garantia: function(garantia) {
				this.garantia = garantia;
			}
			,contacto_preferido: function(contacto) {
				document.querySelectorAll('input[type=checkbox][data-tipo=contacto_preferido]').forEach((input, index) => {
					if (index != contacto)
						if (input.checked)
							input.click();
				});
			}
			,contacto_factura: function(contacto) {
				document.querySelectorAll('input[type=checkbox][data-tipo=contacto_factura]').forEach((input, index) => {
					if (index != contacto)
						if (input.checked)
							input.click();
				});
			}
			,enviar_observacion: async function() {
				try {
					this.cliente.observaciones = (await api.enviar_observacion(this.cliente.id, {observacion: this.nueva_observacion})).data;

					this.nueva_observacion = null;
				}catch(e) {
					this.$log.info('err', e);
					this.$helper.showAxiosError(e,'Error');
				}
			}
			,enviar_observacion_enter: function(e) {
				if (e.keyCode === 13)
					this.enviar_observacion();
			}
			,cancelar_validar_otp: function() {
				this.otp = {
					destinatario: null
					,codigo: null
					,contacto: null
				}
				this.validar_otp_modal = false;
			}
			,enviar_otp: async function(data, contacto) {
				this.$log.info('otp_valor:', data);
				try {
					if (!this.emailPattern.test(data))
						if (!this.phonePattern.test(data))
							return this.$helper.showMessage('Error','El valor debe ser un email válido o un número telefonico a 10 digitos y solo números','error','alert');

					this.otp.destinatario = data;
					let res = (await apiAccount.enviar_otp(data)).data;

					if (!res.success)
						return this.$helper.showMessage('Error','No se pudo enviar la OTP: '+res.message,'error','alert');

					if (contacto)
						this.otp.contacto = contacto;

					this.$helper.showMessage('Exito','La OTP se envio correctamente');
					this.validar_otp_modal = true;
				}catch(e) {
					this.$log.info('err',e);
					this.$helper.showAxiosError(e, 'Error');
				}
			}
			,validar_otp: async function() {
				try {
					if (!this.otpPattern.test(this.otp.codigo))
						return this.$helper.showMessage('Error','El código OTP consta de 8 digitos');

					let res = (await apiAccount.validar_otp(this.otp)).data;

					if (res.success) {
						if (!this.otp.contacto)
							this.nuevo_contacto.validado = true;
						else {
							this.otp.contacto.validado = true;
							this.otp.contacto.block = true;
						}

						this.cancelar_validar_otp();
					}else
						this.$helper.showMessage('Error','No se pudo validar la OTP: '+e.message,'error','alert');
				}catch(e) {
					this.$log.info('err',e);
					this.$helper.showAxiosError(e, 'Error');
				}
			}
			,cancelar_nuevo_contacto: function() {
				this.nuevo_contacto = {
					tipo: 'email'
					,descripcion: null
					,preferido: null
					,facturas: null
					,validado: false
				}

				this.nuevo_contacto_modal = false;
			}
			,guardar_nuevo_contacto: async function() {
				if (this.nuevo_contacto.tipo != 'telefono' && this.nuevo_contacto.tipo != 'telefono_oficina')
					// if (!this.nuevo_contacto.validado)
					// 	return this.$helper.showMessage('Error','Cuando el tipo de contacto es Email o Celular debe tener validación OTP','error','alert');

				if (this.nuevo_contacto.tipo == 'telefono' && this.nuevo_contacto.tipo == 'telefono_oficina') {
					if (!this.phonePattern.test(this.nuevo_contacto.valor))
						return this.$helper.showMessage('Error','El número telefónico debe ser númerico y a 10 digitos','error','alert');
				}

				try {
					this.nuevo_contacto.facturas = this.nuevo_contacto.facturas ? 1 : 0;
					this.nuevo_contacto.preferido = this.nuevo_contacto.preferido ? 1 : 0;

					let res = (await api.guardar_contacto(this.cliente.id, this.nuevo_contacto)).data;

					this.cliente.contactos = res;
					this.cliente_org.contactos = this.$helper.clone(res);
					this.cancelar_nuevo_contacto();
				}catch(e) {
					this.$log.info('err',e);
					this.$helper.showAxiosError(e,'Error');
				}
			}
			,remover_validacion_contacto: function(id) {
				for(let i=0; i<this.cliente.contactos.length; i++) {
					if (this.cliente.contactos[i].id == id) {
						for (let a=0; a<this.cliente_org.contactos.length; a++) {
							if (this.cliente_org.contactos[a].id == id) {
								this.cliente.contactos[i].validado = this.cliente.contactos[i].tipo == this.cliente_org.contactos[a].tipo;

								if (this.cliente.contactos[i].validado)
									this.cliente.contactos[i].validado = this.cliente.contactos[i].valor == this.cliente_org.contactos[a].valor
							}
							a = this.cliente_org.contactos.length;
						}
						i = this.cliente.contactos.length;
					}
				}
			}
			,guardar_cambios_contacto: async function(id,mostrar_alert) {
				let contacto = null;

				for(let i=0; i<this.cliente.contactos.length; i++) {
					if (this.cliente.contactos[i].id == id) {
						contacto = this.cliente.contactos[i];
						i = this.cliente.contactos.length;
					}
				}

				let contacto_org = null;
				for(let a=0; a<this.cliente_org.contactos.length; a++) {
					if (this.cliente_org.contactos[a].id == id) {
						contacto_org = this.cliente_org.contactos[a];
						a = this.cliente_org.contactos.length;
					}
				}

				if (!contacto || !contacto_org)
					return;

				if (!contacto.block) {
				// if (!contacto.block || contacto.validado == 0) {
					if (contacto.tipo == 'email') {
						if (!this.emailPattern.test(contacto.valor)) {
							return this.$helper.showMessage('Error','Tienes que especificar un email válido')
						}
					}else {
						if (!this.phonePattern.test(contacto.valor)) {
							return this.$helper.showMessage('Error','Tienes que especificar un número telefónico válido (solo números y a 10 digitos)', 'error', 'alert');
						}
					}

					if (contacto_org.tipo != contacto.tipo)
						contacto.validado = false;

					if (contacto_org.valor != contacto.valor)
						contacto.validado = false;

					let sinValidacion = ['telefono','telefono_oficina'];
					// if (sinValidacion.indexOf(contacto.tipo) === -1 && contacto.validado == 0)
					// 	return this.$helper.showMessage('Error','Cuando cambias el tipo de dato o el valor de contacto tienes que volver a validar con una OTP','error','alert');
				}

				try {
					let res = (await api.modificar_contacto(this.cliente.id, id, contacto)).data;
					this.cliente.contactos = res;
					this.cliente_org.contactos = this.$helper.clone(res);

					if (typeof(mostrar_alert) == 'undefined' || mostrar_alert)
						this.$helper.showMessage('Contacto actualizado','Los datos de contacto se guardaron correctamente');
				}catch(e) {
					this.$log.info('err',e);
					this.$helper.showAxiosError(e, 'Error');
				}
			}
			,eliminar_contacto: async function(id) {
				if (this.cliente.contactos.length == 1)
					return this.$helper.showMessage('Error','Siempre tiene que tener al menos una forma de contacto','error','alert');

				try {
					let res = (await api.eliminar_contacto(this.cliente.id, id)).data;
					this.$helper.showMessage('Contacto eliminado','Los datos de contacto se eliminaron correctamente');

					this.cliente.contactos = res;
					this.cliente_org.contactos = this.$helper.clone(res);
				}catch(e) {
					this.$log.info('err', e);
					this.$helper.showAxiosError(e,'Error');
				}
			}
			,guardar_cambios: async function() {
				const rfcPattern = /^([A-ZÑ\x26]{3,4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1]))((-)?([A-Z\d]{3}))?$/;
				const curpPattern = /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/;
				const datePattern = /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/;

				if (process.env.VUE_APP_ENV == 'production') {
					if (this.cliente.rfc != '' && this.cliente.rfc != null && !rfcPattern.test(this.cliente.rfc))
						return this.$helper.showMessage('Error','Debes ingresar un RFC válido','error','alert');

					if (this.cliente.curp != '' && this.cliente.curp != null && !curpPattern.test(this.cliente.curp))
						return this.$helper.showMessage('Error','Debes ingresar el CURP válido','error','alert');
				}

				if (this.cliente.fecha_nacimiento != '' && this.cliente.fecha_nacimiento != null && !datePattern.test(this.cliente.fecha_nacimiento))
					return this.$helper.showMessage('Error','Debes ingresar una fecha válida','error','alert');

				try {
					// for(let i=0; i<this.cliente.contactos.length; i++) {
					// 	let contacto_res = (await this.guardar_cambios_contacto(this.cliente.contactos[i].id, false));
					// 	this.$log.info('contacto_res', contacto_res);

					// 	if (contacto_res !== undefined) {
					// 		i = this.cliente.contactos.length;
					// 		return false;
					// 	}
					// };

					if (!this.cliente.rfc)
						delete this.cliente.rfc;

					if (!this.cliente.curp)
						delete this.cliente.curp;

					if (!this.cliente.fecha_nacimiento)
						delete this.cliente.fecha_nacimiento;

					this.cliente = (await api.guardar_cambios_cliente(this.cliente.id, this.cliente)).data;

					this.$helper.showMessage('Datos guardados','Todos los datos se guardaron correctamente');
					this.cerrar_detalles();
				}catch(e) {
					this.$log.info('err', e);
					this.$helper.showAxiosError(e, 'Error');
				}
			}
			,cancelar_ver_facturas: function() {
				this.facturas = [];
				this.optionsFacturas = {
					page: 1
					,order_col: 'id'
					,order_dir: 'desc'
					,limit: 20
					,filters: []
				};
				this.ver_facturas = false;
				this.eventos_anclados = false;
			}
			,buscar_botones_facturas: function() {
				this.$log.info('buscar botones');

				if (!this.eventos_anclados) {
					let parent = this;
					document.querySelectorAll('button.btn-facturas').forEach(btn => {
						btn.addEventListener('click', function(e) {
							parent.reenviar_factura_btn(e.target.getAttribute('data-id'));
						});
					});
					this.eventos_anclados = true;
				}
			}
			,reenviar_factura_btn: function(id) {
				this.$log.info('factura id', id);
				this.reenviar_factura.id = id;

				let correo = null;
				let correo_secundario = null;
				for(let i=0; i<this.cliente.contactos.length; i++) {
					if (this.cliente.contactos[i].tipo == 'email' && this.cliente.contactos[i].facturas == 1)
						correo = this.cliente.contactos[i].valor;

					if (this.cliente.contactos[i].tipo == 'email')
						correo_secundario = this.cliente.contactos[i].valor;
				}

				if (!correo && correo_secundario)
					this.$helper.showMessage('Alerta','No hay un email destiando al envio de facturas, pero se encontró un email validado','warn','alert');

				if (!correo && !correo_secundario)
					return this.$helper.showMessage('Error','No se encontró ningún email para poder enviar las facturas','error','alert');

				this.reenviar_factura.email = correo ? correo : correo_secundario;
				this.reenviar_factura_modal = true;
			}
			,cancelar_reenviar_factura: function() {
				this.reenviar_factura = {
					id: null
					,email: null
				}
				this.reenviar_factura_modal = false;
			}
			,reenviar_factura_peticion: async function() {
				if (!this.emailPattern.test(this.reenviar_factura.email))
					return this.$helper.showMessage('Error','Tienes que definir un email válido','error','alert');

				try {
					let res = (await api.reenviar_facturas(this.credito_seleccionado.no_credito, this.reenviar_factura)).data;

					if (!res.success)
						return this.$helper.showMessage('Error','No se pudo reenivar la factura: '+res.message,'error','alert');

					this.$helper.showMessage('Reenviar factura','El reenvio de facturas fue correcto');
					this.cancelar_reenviar_factura();
				}catch(e) {
					this.$log.info('err',e);
					this.$helper.showAxiosError(e,'Error');
				}
			}
			,solicitar_acceso: async function() {
				if (process.env.VUE_APP_ENV == 'dev') {
					this.cliente_temporal = this.seleccionadas[0];
					return this.ver_detalles(this.cliente_temporal.id);
				}

				if (this.seleccionadas.length == 0)
					return this.$helper.showMessage('Error','Tienes que seleccionar un cliente','error','alert');

				this.cliente_temporal = this.seleccionadas[0];
				this.$log.info('cliente temporal', this.cliente_temporal);
				this.metodos_validacion = (await api.listar_metodos_validacion(this.cliente_temporal.id)).data;
				this.ver_metodos_validacion = true;
			}
			,ocultar_valor: function(val) {
				return val.indexOf('@') != -1 ? '***********'+val.substr(val.indexOf('@')) : '*******'+val.substr(6)
			}
			,cancelar_validacion: function() {
				this.cliente_temporal = null;
				this.metodo_validacion = [];
				this.seleccionar = false;
				this.otp_validacion = {
					destinatario: null
					,codigo: null
					,razones: null
				}
				this.ver_validar_codigo_modal = false;
				this.ver_metodos_validacion = false;
			}
			,seleccion_unico_metodo_validacion: function(e, valor) {
				document.querySelectorAll('input[data-tipo=solicitar_acceso_valor]').forEach(input => {
					 if (input.checked)
					 	input.click();
				});

				e.target.click();

				this.otp_validacion.destinatario = valor;
			}
			,enviar_codigo_acceso: async function() {
				this.otp_validacion.destinatario = document.querySelector('input[data-tipo=solicitar_acceso_valor]:checked').value;
				this.$log.info('destinatario',this.otp_validacion.destinatario);

				try {
					if (this.otp_validacion.destinatario == 'sistema') {
						(await api.acceso_sistema({cliente_id: this.cliente_temporal.id, cliente_nombre: this.cliente_temporal.nombre_completo, razones:this.otp_validacion.razones})).data

						this.ver_detalles(this.cliente_temporal.id);
						this.cancelar_validacion();
						return;
					}

					let res = (await apiAccount.enviar_otp(this.otp_validacion.destinatario)).data;

					if (res.success) {
						this.$helper.showMessage('Solicitud enviada','La solicitud se envio correctamente');
						this.ver_validar_codigo_modal = true;
					}else
						this.$helper.showMessage('Error','No se pudo enviar la solicitud: '+res.message,'error','alert');
				}catch(e) {
					this.$log.info('err',e);
					this.$helper.showAxiosError(e,'Error');
				}
			}
			,validar_codigo_acceso: async function() {
				try {
					let res = (await apiAccount.validar_otp(this.otp_validacion)).data;

					if (res.success) {
						this.ver_detalles(this.cliente_temporal.id);
						this.cancelar_validacion();
					}
					else
						this.$helper.showMessage('Error','No se pudo validar el código: '+res.message,'error','alert');
				}catch(e) {
					this.$log.info('err',e);
					this.$helper.showAxiosError(e,'Error');
				}
			}
			,cancelar_validacion_codigo: function() {
				this.otp_validacion.codigo = null;
				this.ver_validar_codigo_modal = false;
			}
			,cancelar_direccion_nueva: function() {
				this.direccion_nueva = {
					mostrar: false
					,calle: null
					,no_interior: null
					,no_exterior: null
					,colonia: null
					,delegacion: null
					,estado: null
					,cp: null
					,principal: 0
				};
				this.direccion_nueva_origen = 'manual';
				this.direccion_nueva_seleccionada = 'default';
			}
			,guardar_direccion_nueva: async function() {
				try {
					let res = (await api.guardar_direccion_cliente(this.cliente.id, this.direccion_nueva)).data;

					this.$helper.showMessage('Exito!','La dirección se guardo correctamente');
					this.cliente.direcciones = res;
					this.cancelar_direccion_nueva();
				}catch(e) {
					this.$log.info('err',e);
					this.$helper.showAxiosError(e,'Error');
				}
			}
			,guardar_cambios_direccion: async function(id) {
				let direccion = null;

				this.cliente.direcciones.forEach(tmp => {
					if (tmp.id == id)
						direccion = this.$helper.clone(tmp);
				})

				Object.keys(direccion).forEach(key => {
					if (!direccion[key])
						delete direccion[key];
				});

				try {
					let res = (await api.actualizar_direccion_cliente(this.cliente.id, direccion.id, direccion)).data;

					this.$helper.showMessage('Exito!','Se guardo correctamente la dirección correctamente');
					this.cliente.direcciones = res;
				}catch(e) {
					this.$log.info('err',e);
					this.$helper.showAxiosError(e,'Error');
				}
			}
			,eliminar_direccion: async function(id, principal) {
				if (this.cliente.direcciones.length == 1)
					return this.$helper.showMessage('Error','Tienes que dejar al menos una dirección','error','alert');

				if (principal)
					return this.$helper.showMessage('Error','No puedes eliminar una dirección cuando esta marcada como principal','error','alert');

				try {
					let res = (await api.eliminar_direccion_cliente(this.cliente.id, id)).data;

					this.$helper.showMessage('Exito!','Se eliminó la dirección correctamente');
					this.cliente.direcciones = res;
				}catch(e) {
					this.$log.info('err',e);
					this.$helper.showAxiosError(e,'Error');
				}
			}
			,enviar_entrevista: async function() {
				try {
					this.credito_seleccionado = (await api.actualizar_credito(this.cliente.id,this.credito_seleccionado.id, {avatar_estatus: 1})).data;
				}catch(e) {
					this.$log.info('Error',e);
					this.$helper.showAxiosError(e, 'Error')
				}
			},
			 actualizar_informacion(){
				this.cerrar_detalles();
				this.obtener_clientes();
				this.modal_reestructurar_credito = false;
				this.ver_creditos = false;
			}	
		}
		,computed: {
			monto_aprobado_formato: function() {
				return '$'+this.$helper.moneyFormat(this.credito_seleccionado.monto_aprobado);
			}
			,monto_ejercido_formato: function() {
				return '$'+this.$helper.moneyFormat(this.credito_seleccionado.monto_ejercido);
			}
			,garantia_valor_format: function() {
				return '$'+this.$helper.moneyFormat(this.garantia.valor);
			}
			,importe_solicitado_format: function() {
				return '$'+this.$helper.moneyFormat(this.credito_seleccionado.importe_solicitado);
			}
			,importe_seleccionado_format: function() {
				return '$'+this.$helper.moneyFormat(this.credito_seleccionado.importe_seleccionado);
			}
			,importe_pre_autorizado_format: function() {
				return '$'+this.$helper.moneyFormat(this.credito_seleccionado.importe_pre_autorizado);
			}
			,pago_referencia: function() {
				return this.info_pago.bankAccountReference;
			}
			,monto_pagar: function() {
				return '$'+this.$helper.moneyFormat(this.info_pago.creditIdealPayment);
			}
			,listar_garantias: function() {
				let garantias = [];

				this.cliente.creditos.forEach(credito => {
					credito.garantias.forEach(garantia => {
						garantias.push(garantia);
					});
				});

				return garantias;
			}
		}
		,watch: {
			nuevo_contacto: {
				handler: function(val) {
					if (val.tipo != 'email')
						val.factura = false;

					return val;
				}
				,deep: true
			}
			,direccion_nueva_origen: function(val) {
				// this.$log.info('direccion_nueva_origen', val);
				if (val == 'garantia')
					this.direccion_nueva_seleccionada = 'default';
				else
					this.direccion_nueva = {
						mostrar: true
						,calle: null
						,no_interior: null
						,no_exterior: null
						,colonia: null
						,delegacion: null
						,estado: null
						,cp: null
						,principal: 0
					}
			}
			,direccion_nueva_seleccionada: function(val) {
				// this.$log.info('id', val);
				this.listar_garantias.forEach(garantia => {
					if (garantia.id == val) {
						// this.$log.info('garantia', garantia);
						this.direccion_nueva = {
							mostrar: true
							,id: garantia.id
							,calle: garantia.calle
							,no_exterior: garantia.no_exterior
							,no_interior: garantia.no_interior
							,colonia: garantia.colonia
							,delegacion: garantia.delegacion
							,estado: garantia.entidad
							,cp: garantia.cp
							,principal: 0
						};
					}
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	fieldset {
		border-width: 2px !important;
		border-style: groove !important;
		border-radius: 3px;
	}

	legend {
		width: auto;
		margin-left: 10px;
		padding: 3px 10px;
	}

	.table-header {
		.table-observaciones {
			margin-bottom: 0px;

			thead {
				background-color: #000;

				th:nth-child(1) {
					width: 10%;
				}

				th:nth-child(2) {
					width: 10%;
				}

				th:nth-child(3) {
					width: 10%;
				}
			}
		}
	}

	.table-container {
		width: 100%;
		max-height: 30vh;
		overflow: auto;
		// overflow-x: hidden;

		tbody {
			tr {
				td {
					word-wrap: break-word;
				}

				td:nth-child(1) {
					width: 10%;
					vertical-align: top;
				}

				td:nth-child(2) {
					width: 10%;
					vertical-align: top;
				}

				td:nth-child(3) {
					width: 10%;
					vertical-align: top;
				}

				td:nth-child(4) {
					width: 70%;
					word-break: break-word !important;
				}
			}
		}
	}

	.table-observaciones-container {
		max-height: 50vh;
		overflow: auto;
	}

	.table-cliente-observaciones {
		thead {
			background-color: #000;

			th:nth-child(1) {
				width: 15%;
			}

			th:nth-child(2) {
				width: 10%;
			}

			th:nth-child(3) {
				width: 75%;
			}
		}

		tbody {
			tr {
				td:nth-child(1) {
					width: 15%;
				}

				td:nth-child(2) {
					width: 10%;
				}

				td:nth-child(3) {
					width: 75%;
				}
			}
		}
	}
</style>